<!-- Finder particular de Funerarias Sucursales -->

<template> 
  <div class="funesF" v-if="loaded" >   
   
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera" v-if="!viewMtoOnly" >     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>


        <!-- Contenido -->
        <div class="contenedor"> 

          <!-- <div v-html="info"></div> -->

          <!-- Filtro de Búsqueda -->         
          <div style="display:flex; align-items:center">
            <div class="columna" style="width:28rem;padding-right:5px">
              
              <v-select
                v-bind="$select"
                v-model="ct.zona[2]"
                :label="ct.zona[1]"
                :items="ST_zonas"
                item-value="id"
                item-text="name"
                :disabled="zona >0">
              </v-select>

              <div style="display:flex">
                <uiText   
                  v-bind="$input"              
                  v-model="ct.name[2]"
                  :label="ct.name[1]"
                  @Enter="eventFiltro('buscarFuneraria')">                
                </uiText>

                <v-btn style="margin-left:5px" v-bind="$cfe.btn.busca" @click="eventFiltro('buscarFuneraria')">
                  <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
                </v-btn>
              </div>

              <v-checkbox
                v-if="app== 1"
                v-bind="$checkbox"
                v-model="ct.bajas[2]"
                :label="ct.bajas[1]">
              </v-checkbox>
            </div>

            <div class="columna" style="width:28rem;padding-right:5px">
              <!-- <ctrlF v-if="app== 1" :ct="ct.ciac"></ctrlF> -->
              <ctrlF :ct="ct.loc"></ctrlF>

              <div class="inline">
                <v-btn v-bind="$cfe.btn.buscaTexto" @click="eventFiltro('buscarOrigen')">
                    Origen
                  <v-icon right dark>{{ $cfe.btn.buscaTexto.icono }}</v-icon>
                </v-btn>

                <v-btn
                  v-if="app== 1"                
                  v-bind="$cfe.btn.buscaTexto"
                  @click="buscarAsignacion">                
                    Asignación
                  <v-icon right dark>{{ $cfe.btn.buscaTexto.icono }}</v-icon>
                </v-btn>

                <v-btn
                  v-bind="$cfe.btn.buscaTexto"
                  @click="eventFiltro('buscarPresupuestos')">
                    Presupuestos
                  <v-icon right dark>{{ $cfe.btn.buscaTexto.icono }}</v-icon>
                </v-btn>
              </div>
            </div>                                          
          </div>
       

          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"           
            dense
            @onEvent="execAccion">
         
              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">    
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>
                
                </div>
              </template>

              <!-- Slot Compañías Centrales -->
              <template v-slot:ciac="{ item }">
                {{ item.ciac? item.ciac.substr(0, 8) : '' }}
              </template>
                    
              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>
      
      
      <!-- Mto Funerarias Sucursales  -->      
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>       

    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto"; 
  import ctrlF from "@/components/ctrlF";
  import funesM from "@/components/funesM";
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, ctrlF, funesM },
    props: {},  

    data() {
      return {  
        stIni: {
          api: "funesF",
          titulo:"Funerarias Sucursales", 
          name:"funesF",
          mView:'funesM',
          pView:[]
        },
               
        ST_zonas: [],     
      };
    },
    
    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:['buscarFuneraria', 'buscarOrigen'],
          fields:[        
            { text: "Acciones", value: "acciones", sortable: false, slot:true, width: "15%" },
            { text: "Funeraria", value: "name", witdh: "40%" },
            { text: "Origen", value: "name_loc", witdh: "35%" },
            { text: "Situ", value: "situ", witdh: "10%" }          
          ]},
          
          {name:['buscarAsignacion'],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, slot:true, width: "10%" },
            { text: "Funeraria", value: "name", witdh: "30%" },
            { text: "Origen", value: "name_loc", witdh: "15%" },
            { text: "Asignación.", value: "asignacion", witdh: "15%" },
            { text: "Compañía", value: "ciac", slot:true, witdh: "20%" },
            { text: "Situ", value: "situ", witdh: "10%" }           
          ]},

          {name:['buscarPresupuestos'],
          fields:[
            { text: "Acciones", value: "acciones", sortable: false, slot:true, width: "10%" },
            {text: "Compañía", value: "cianame", witdh: "10%" },
            { text: "Funeraria", value: "name", witdh: "25%" },
            { text: "Municipio", value: "municipio", witdh: "15%" },
            { text: "Pft", value: "preferente", witdh: "5%" },
            { text: "Tipo", value: "tipo", witdh: "5%" },
            { text: "Descripción", value: "descrip", witdh: "10%" },
            { text: "Imp fun", value: "pnfsuma", witdh: "10%" },
            { text: "Importe", value: "imp", witdh: "10%" },
            { text: "Imp Cia", value: "imp_cia", witdh: "10%" }          
          ]}

        ];

        this.headerGrid= this.headerSet();
        
        // obtengo items zonas 
        this.ST_zonas = this.$store.state.datos_iniciales.zonas;
        this.ST_zonas.unshift({ id: "0", name: "Todas" });
        this.ct.zona[2]= this.zona;       

        //cia omision
        this.ct.ciac[2]='83';
      },  


      //
      buscarAsignacion() {
        if (!this.ct.ciac[2]) {
          this.$root.$alert.open('Debe seleccionar una Compañía', 'info');
          return;
        }
        
        this.eventFiltro('buscarAsignacion');
      }

    }
    
  }
</script>


