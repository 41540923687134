<!-- Mto particular de Funerarias Sucursales -->

<template>
  <div class="funesM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">

          <div>
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>  
            
            <v-sheet :elevation="4">
              <div class="contenedor" style="width:auto">

                <!-- <div v-html="info"></div> -->

                <!-- Botones Mto -->
                <div class="pt-2 d-flex justify-left" style="justify-content:space-between;width:360px">
                  
                  <!-- Mto -->            
                  <baseBtraMto
                    v-if="!readOnly"
                    :perm="permMto"
                    :modulo="btMtoCfg" 
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>
               
                  <!-- Extra -->            
                  <div class="conflex" style="margin-left: 50px">                         
                    <baseBtraExtra class="conflex"           
                      :permExtra="permExtra"
                      :modulo="btExtCfg"           
                      @onEvent="execAccion">             
                    </baseBtraExtra>                  
                  </div>           
                </div>
  
                <!-- Controles del Mto -->
                <div class="conflex" style="padding-top:10px">
                  <div class="columna" style="width:450px" >

                    <div class="cab">DATOS GENERALES</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <uiText   
                        styleInput="font-weight: bold;"                    
                        v-model="ct.name[2]"
                        :label="ct.name[1]"
                        :disabled="noEdit"
                        uCase>                      
                      </uiText>

                      <uiText                        
                        v-model="ct.dire[2]"
                        :label="ct.dire[1]"
                        :disabled="noEdit">                      
                      </uiText>

                      <ctrlF               
                        :ct="ct.loc"
                        :edicion="!noEdit">                        
                      </ctrlF>

                      <div class="conflex">
                        <uiText  
                          style="width:50%"
                          v-model="ct.cp[2]"
                          :label="ct.cp[1]"                        
                          :disabled="noEdit">                      
                        </uiText>

                        <uiText            
                          style="width:50%"           
                          v-model="ct.cif[2]"
                          :label="ct.cif[1]"
                          :disabled="noEdit"
                          format="cif">                      
                        </uiText>
                      </div>
                    </v-sheet>

                    <div class="cab">CENTRAL</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <ctrlF                       
                        :ct="ct.idc"
                        :edicion="!noEditAppAPD">
                      </ctrlF>
                  
                      <uiText                    
                        v-model="ct.grupo[2]"
                        :label="ct.grupo[1]"
                        :disabled="true">
                      </uiText>
                    </v-sheet>

                  </div>

                  <div class="columna" style="width:260px">
                    <div class="cab">CLASIFICACIÓN</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <v-select
                        v-bind="$select"
                        :items="itemsSituacion"
                        :label="ct.situ[1]"              
                        :disabled="noEditAppAPD"
                        item-value="id"
                        item-text="label"
                        v-model="ct.situ[2]">
                      </v-select>

                      <v-select
                        v-bind="$select"
                        :items="itemsTipo"
                        :label="ct.tipo[1]"
                        :disabled="noEditAppAPD"
                        item-value="id"
                        item-text="name"
                        v-model="ct.tipo[2]">
                      </v-select>
                    </v-sheet>

                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <uiText
                          style="width:50%"
                          v-model="ct.ref_cia[2]"
                          :label="ct.ref_cia[1]"
                          :disabled="noEditAppAPD">
                        </uiText>

                        <uiText
                          style="width:50%"
                          v-model="ct.ccont[2]"
                          :label="ct.ccont[1]"
                          :disabled="noEditAppAPD">
                        </uiText>
                      </div>
                    </v-sheet>

                    <v-sheet v-show="app==1" v-bind="$cfe.styles.marco">
                      <div class="conflex">
                        <uiText
                          v-model="ct.tcs[2]"
                          :label="ct.tcs[1]"
                          :disabled="true">
                        </uiText>

                        <v-btn                     
                          class="white--text ml-2" 
                          :disabled="noEditAppAPD"  
                          v-bind="$cfg.btra_cfg.standard" 
                          @click="cs()">
                            CS
                        </v-btn>
                      </div>

                      <div class="conflex" style="justify-content:space-between;width:150px">
                        <v-checkbox
                          v-bind="$checkbox"
                          v-model="ct.tpe[2]"
                          :label="ct.tpe[1]"
                          :disabled="noEditAppAPD">
                        </v-checkbox>
                      
                        <v-checkbox
                          v-bind="$checkbox"
                          v-model="ct.pdi[2]"
                          :label="ct.pdi[1]"
                          :disabled="noEditAppAPD">
                        </v-checkbox>
                      </div>

                      <div class="conflex">
                        <v-select
                          style="width:70%"
                          v-bind="$select"
                          v-model="ct.tRF[2]"
                          :label="ct.tRF[1]"
                          :items="itemsFiscal"                
                          :disabled="noEditAppAPD"
                          item-value="id"
                          item-text="name">                
                        </v-select>

                        <uiText
                          style="width:25%;margin-left:5px"
                          v-model="ct.pRF[2]"
                          :label="ct.pRF[1]"
                          :disabled="noEditAppAPD">
                        </uiText>
                      </div>
                    </v-sheet>            
                  </div>

                  <div class="columna" style="width:480px">
                    <div v-show="app==1">
                      <uiObs3                       
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs"
                        :edicion="!noEditAppAPD">                       
                      </uiObs3>
                    </div>

                    <uiObs3                     
                      :record="record"
                      :schema="ct"
                      :schemaComponente="ct.obsjz"
                      :edicion="app==2 && noEdit">
                    </uiObs3>

                  </div>
                </div>
                      
    
                <v-tabs
                  v-model="tabOpcion"
                  background-color="blue-grey darken-1"
                  active-class="blue-grey lighten-3 font-weight-bold"
                  :align-with-title="true"
                  dark
                  show-arrows
                  hide-slider
                  height="25px">        
                    <v-tab v-for="seccion in secciones" :key="seccion.titulo">
                      <v-icon class="pr-2" small>{{ seccion.icono }}</v-icon>
                      {{ seccion.titulo }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabOpcion">
                  <v-tab-item
                    v-for="seccion in secciones"
                    :key="seccion.titulo"
                    :transition="false"
                    :reverse-transition="false">
                  
                      <div style="background-color: #eee;"> 

                          <component                            
                            :is="seccion.comp"
                            sync
                            :ct="ct"
                            :noEdit="noEdit"                            
                            :masterStore="storeName"                            
                            :readOnly="readOnly">
                          </component>

                      </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-sheet>


            <!-- Ventana de Componentes Extra  -->      
            <component      
              :is="componenteDinamico"
              syncUpdate              
              :storeRaiz="storeName"
              :masterStore="storeName"      
              :readOnly="readOnly"                  
              tipoDocumento='1'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component> 
         
          </div>
        </template>
      </dualTemplate>           
  </div>
</template>


<script>
 
  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";  
  import dualTemplate from "@/components/dualTemplate";
  import ctrlF from "@/components/ctrlF";
  import funesDatos from "@/components/funesDatos.vue";
  import funesPresF from "@/components/funesPresF.vue";
  import commonDocsF from "@/components/commonDocsF.vue";
  import commonFrasF from "@/components/commonFrasF.vue";
   
  export default {
    mixins: [mixM],
    components: {  
      baseHeader,
      baseBtraMto,
      baseBtraExtra,
      dualTemplate,      
      ctrlF,
      funesDatos,
      funesPresF,
      commonDocsF,
      commonFrasF
    },

    props: {},

    data() {
      return {
        stIni: {
          api: "funesM",
          name:"funesM",
          titulo:"Funerarias Sucursales",  
          recordAccess:"local",
          mView:'',
          pView:[]
        },  

        tabOpcion: null,
        Asecciones: [
          { titulo: "Datos", icono: "mdi-phone",comp: "funesDatos" },
          { titulo: "Presupuestos", icono: "mdi-filter",comp: "funesPresF", show:!this.readOnly }
        ],

        itemsSituacion:[],
        itemsTipo:[],
        itemsFiscal:[],      
      };
    },


    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { "accion":'documentos', "btn": "docs" },
          { "accion":'facturas', "btn": "facturas" }
        ];
               
        // obtengo items adicionales
        this.itemsSituacion= this.$store.state.datos_iniciales.situ;
        this.itemsTipo= this.$store.state.datos_iniciales.tipo;  
        this.itemsFiscal= this.$store.state.datos_iniciales.tipo_regfiscal;
      },
      
      
      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.botonerasSet();
      },
         

      //
      botonerasSet() {
        // BOTONERA MTO
        this.btnSet(this.btMtoCfg, 1, { view: true, disabled:this.app!=1 });
        this.btnSet(this.btMtoCfg, 3, { view: true, disabled:this.app!=1 });        

        // BOTONERA EXTRA        
        this.btnSet(this.btExtCfg, 'documentos', { view: true, disabled:this.estado== 'nuevo'? true : false });
        this.btnSet(this.btExtCfg, 'facturas', { view: true, disabled:this.estado== 'nuevo'? true : false });        
      },


      // descargo el contrato de la funeraria
      view_contrato(){
        console.log("muestro contrato");
      },

      //------------------------------------------------

      // tramitación capital sobrante
      cs() {       
        var txt= Number(this.ct.tcs[2])=== 10? 'ELIMINAMOS' : 'CONFIRMAMOS';
        this.$root.$alert.open("¿" + txt + " 10% de Tramitación CS?", "confirm").then(r => {
          if (r) this.cs_continue()          
        });
      },

      cs_continue() {                
        let value= '';
        if (Number(this.ct.tcs[2])=== 10) {
            this.$set(this.ct.tcs, 2, '0.00');

            value= "Eliminado porcentaje Tramitación CS " + this.ct.tcs[2];
            this.$set(this.ct.obs[4].comp, 'valueAux1', value);
            return;
        }

        this.$set(this.ct.tcs, 2, '10');

        value= "Añadido porcentaje Tramitación CS " + this.ct.tcs[2];                
        this.$set(this.ct.obs[4].comp, 'valueAux1', value);
      },


      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        this.componenteDinamico= 'commonDocsF';
      },

      //
      facturas() {
        this.componenteDinamico= 'commonFrasF';        
      }

    },


    computed: {
 
      noEditAppAPD() {
        if (this.app=='2' ) return true;       
        return this.noEdit;
      },


      secciones() {
        return this.Asecciones.filter(item=> { 
          if (typeof item.show=== 'undefined') return item;
          if (item.show) return item;
        });
      }
       
    },
  };
</script>
