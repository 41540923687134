<!-- Mto particular de Datos de Funerarias Sucursales -->

<template>
  <div class="funesDatos conflex" style="justify-content:center" >

    <!-- Contenido -->
    <div class="contenedor conflex">

      <!-- COLUMNA IZQUIERDA -->
      <div class="columna" style="width:50%">
        <div class="cab">TELÉFONOS/FAX</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div class="conflex">
          
            <uiText
              style="width:25%"
              v-model="ct.tlf1[2]"
              :label="ct.tlf1[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              style="width:25%"
              v-model="ct.tlf2[2]"
              :label="ct.tlf2[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              style="width:25%"
              v-model="ct.tlf3[2]"
              :label="ct.tlf3[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              style="width:25%"
              v-model="ct.tlf4[2]"
              :label="ct.tlf4[1]"
              :disabled="noEdit">
            </uiText>
          </div>

          <!-- Segunda fila de telefonos -->
          <div class="conflex">
            <uiText        
              style="width:25%"    
              v-model="ct.tlf5[2]"
              :label="ct.tlf5[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              style="width:25%"
              v-model="ct.tlf6[2]"
              :label="ct.tlf6[1]"
              :disabled="noEdit">
            </uiText>

            <uiText    
              style="width:25%"        
              v-model="ct.fax1[2]"
              :label="ct.fax1[1]"
              :disabled="noEdit">
            </uiText>

            <uiText    
              style="width:25%"        
              v-model="ct.fax2[2]"
              :label="ct.fax2[1]"
              :disabled="noEdit">
            </uiText>
          </div>
        </v-sheet>

        <div class="cab">CONTACTO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div style="display:flex">
            <v-checkbox
              v-bind="$checkbox"
              v-model="ct.contratador[2]"
              :label="ct.contratador[1]"
              :disabled="noEdit">
            </v-checkbox>

            <uiText    
              style="margin-left:10px"        
              v-model="ct.cont1[2]"
              :label="ct.cont1[1]"
              :disabled="noEdit">
            </uiText>
          </div>

          <uiText          
            v-model="ct.cont3[2]"
            :label="ct.cont3[1]"
            :disabled="noEdit">
          </uiText>

          <div style="display:flex">
            <uiText            
              v-model="ct.email1[2]"
              :label="ct.email1[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              v-model="ct.email2[2]"
              :label="ct.email2[1]"
              :disabled="noEdit">
            </uiText>
          </div>
          
          <uiUrl
            v-model="ct.url[2]"
            :label="ct.url[1]"
            :disabled="noEdit">
          </uiUrl>
        </v-sheet>

        <!-- FIN COLUMNA IZQUIERDA -->
      </div>

      <!-- COLUMNA DERECHA -->
      <div class="columna" style="width:50%">
        <div class="cab">ADMINISTRACIÓN</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div class="conflex">
            <uiText            
              v-model="ct.cont2[2]"
              :label="ct.cont2[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              v-model="ct.admonemail[2]"
              :label="ct.admonemail[1]"
              :disabled="noEdit">
            </uiText>
          </div>

          <div style="display:flex">
            <uiText            
              v-model="ct.admontlf1[2]"
              :label="ct.admontlf1[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              v-model="ct.admonfax1[2]"
              :label="ct.admonfax1[1]"
              :disabled="noEdit">
            </uiText>
          </div>

          <div style="display:flex">
            <uiText            
              v-model="ct.ban[2]"
              :label="ct.ban[1]"
              :disabled="noEdit">
            </uiText>

            <uiText            
              v-model="ct.cta[2]"
              :label="ct.cta[1]"
              format="banco"
              :disabled="noEdit">
            </uiText>
          </div>
        </v-sheet>

        <div class="cab">OFICINA COMERCIAL</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div class="conflex">
            <v-checkbox
              v-bind="$checkbox"
              v-model="ct.oficom[2]"
              :label="ct.oficom[1]"
              :disabled="noEditAppAPD">
            </v-checkbox>

            <uiText    
              style="margin-left:10px"         
              v-model="ct.vname[2]"
              :label="ct.vname[1]"
              :disabled="noEditAppAPD">
            </uiText>
          </div>

          <div class="conflex">
            <div style="width:50%">
              <uiText              
                v-model="ct.vdir[2]"
                :label="ct.vdir[1]"
                :disabled="noEditAppAPD">
              </uiText>
            </div>

            <uiText    
              style="width:25%"        
              v-model="ct.vtlf[2]"
              :label="ct.vtlf[1]"
              :disabled="noEditAppAPD">
            </uiText>

            <uiText  
              style="width:25%"          
              v-model="ct.vfax[2]"
              :label="ct.vfax[1]"
              :disabled="noEditAppAPD">
            </uiText>
          </div>
        </v-sheet>

        <div class="cab">DOTACIÓN</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div class="conflex">
            <uiText      
              style="width:25%"      
              v-model="ct.tana[2]"
              :label="ct.tana[1]"
              :disabled="noEditAppAPD">
            </uiText>

            <uiText    
              style="width:25%"        
              v-model="ct.sala[2]"
              :label="ct.sala[1]"
              :disabled="noEditAppAPD">
            </uiText>

            <!-- <v-input_n
              
              simbolo=''
              v-model="ct.sala[2]"
              :label="ct.sala[1]"
              :disabled="noEditAppAPD">
            </v-input_n> -->

            <uiText 
              style="width:25%"
              v-model="ct.cemen[2]"
              :label="ct.cemen[1]"
              :disabled="noEditAppAPD">
            </uiText>

            <uiText  
              style="width:25%"          
              v-model="ct.horno[2]"
              :label="ct.horno[1]"
              :disabled="noEditAppAPD">
            </uiText>
          </div>

          <div class="conflex">
            <uiText
              style="width:25%"
              v-model="ct.auto[2]"
              :label="ct.auto[1]"
              :disabled="noEditAppAPD">
            </uiText>

            <uiText
              style="width:25%"
              v-model="ct.furgon[2]"
              :label="ct.furgon[1]"
              :disabled="noEditAppAPD">
            </uiText>

            <uiText
              style="width:25%"
              v-model="ct.frig[2]"
              :label="ct.frig[1]"
              :disabled="noEditAppAPD">
            </uiText>

            <uiText
              style="width:25%"
              v-model="ct.salap[2]"
              :label="ct.salap[1]"
              :disabled="noEditAppAPD">
            </uiText>

          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>



<script>
  
  export default {  
    props: {
      ct: { type:Object, default: ()=> {} },
      noEdit: { type:Boolean, default:false }
    },        


    computed: {
      noEditAppAPD() {
        if (this.app=='2' ) return true;       
        return this.noEdit;        
      }
    }

  };
  
</script>
